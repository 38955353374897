require('./overrides.scss')

const preset = {
  theme: {
    themes: {
      light: {
        primary: "#004279",
        secondary: "#43608c",
        accent: "#7C8FAC",
        error: "#FF5252",
        info: "#2D355E",
        success: "#5B975A",
        warning: "#FFC107"
      },
    },
    options: {
      customProperties: true
    },
  },
}

export default preset;
